<template>
  <div class="pin-container d-flex">
    <div v-for="pin in pins" :key="pin.id" class="pinned-item" @click="pinClick(pin)">
      <v-tooltip text="Unpin" location="top">
        <template v-slot:activator="{ props }">
          <qtm-icon-btn
            class="pin-right bg-white"
            v-bind="props"
            size="small"
            icon="mdi-pin"
            @click.stop="removePin(pin.objectId, pin.type)"
          />
        </template>
      </v-tooltip>

      <v-icon size="x-small" :color="getIconColor(pin)" class="pin-left">
        {{ getIcon(pin) }}
      </v-icon>

      <span class="pin-center">{{ pin.title }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import { type Pin, usePins } from '@/composables/pins'

const store = useStore()

const { pins, removePin } = usePins()

const getIcon = (pin: Pin) => {
  if (pin.type === 'order') {
    return 'mdi-clipboard-outline'
  }
  if (pin.type === 'email') {
    return 'mdi-email-outline'
  }
  return 'mdi-pin'
}

const getIconColor = (pin: Pin) => {
  if (pin.type === 'order') {
    return 'primary'
  }
  if (pin.type === 'email') {
    return 'secondary'
  }
  return 'interactive'
}

const pinClick = (pin: Pin) => {
  if (pin.type === 'order') {
    store.commit('admin/activateOrder', { id: pin.objectId })
  }
  else if (pin.type === 'email') {
    store.commit('admin/activateEmail', { id: pin.objectId })
  }
}
</script>

<style lang="scss" scoped>
.pin-container {
  bottom: 0;
  flex-direction: row-reverse;
  overflow-x: auto;
  padding-left: 60px;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 999;
}
.pinned-item {
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 1px -1px 4px #666;
  cursor: pointer;
  height: 40px;
  margin: 4px 5px 0px 5px;
  max-width: 400px;
  min-width: 200px;
  overflow: hidden;
  padding: 8px 10px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pin-left {
  left: 5px;
  position: absolute;
  top: 12px;
}
.pin-right {
  position: absolute;
  right: 5px;
  top: 5px;
}
.pin-center {
  margin-left: 15px;
  padding-right: 20px;
}
</style>
